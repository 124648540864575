import { PropertyType, UserProfile } from '../client'

export const getInitialsFromSlug = (slug?: string) => {
  const initials = slug
    ? slug
        .split('-')
        .map((l) => l.charAt(0))
        .join('')
    : ''

  return (initials.length === 1 ? initials + 'ß' : initials).substring(0, 2)
}

export const getInitialsFromProfile = (profile?: {
  firstName?: string | null
  lastName?: string | null
  email?: string | null
}) => {
  const initials = profile
    ? profile.firstName || profile.lastName
      ? (profile.firstName?.charAt(0) ?? '') +
        (profile.lastName?.charAt(0) ?? '')
      : (profile.email?.charAt(0) ?? '')
    : 'R'

  return initials
}

export const getInitialsFromPropertyType = (type: PropertyType) => {
  switch (type) {
    case 'MV':
      return 'MV'
    case 'WEG':
      return 'WG'
    case 'SEV':
      return 'SV'
  }
}

export const normalizeData = (data?: string) => {
  return (
    data
      ?.toLowerCase()
      .trim()
      .replaceAll(' ', '')
      .replaceAll('-', '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('+', '') ?? null
  )
}

export const normalizeEmailData = (data: string | null | undefined) => {
  return data?.toLowerCase().trim() ?? null
}

export const extractNormalizedEmail = (email?: string | null) => {
  return (
    email
      ?.split(';')
      .flatMap((e) => e!.split(','))
      .flatMap((e) => e!.split('/'))
      .map((e) => e.trim())
      .filter((e) => e !== '')[0]
      ?.toLowerCase() || ''
  )
}

export const extractSecondaryEmail = (email?: string | null) => {
  return email
    ?.split(';')
    .flatMap((e) => e!.split(','))
    .flatMap((e) => e!.split('/'))
    .map((e) => e.trim())
    .filter((e) => e !== '')[1]
    ?.toLowerCase()
}

function hashString(str: string): number {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash // Convert to 32bit integer
  }
  return hash
}

// const TW_COLORS = [
//   'slate',
//   'gray',
//   'zinc',
//   'neutral',
//   'stone',
//   'red',
//   'orange',
//   'amber',
//   'yellow',
//   'lime',
//   'green',
//   'emerald',
//   'teal',
//   'cyan',
//   'sky',
//   'blue',
//   'indigo',
//   'violet',
//   'purple',
//   'fuchsia',
//   'pink',
//   'rose',
// ]

const TW_COLORS_USER_AVATAR_SAFE = [
  'red',
  'orange',
  'amber',
  'yellow',
  'lime',
  'green',
  'emerald',
  'teal',
  'cyan',
  'sky',
  'blue',
  'indigo',
  'violet',
  'purple',
  'fuchsia',
  'pink',
  'rose',
]

export const nameToColor = (email?: string) => {
  if (!email) return 'gray'

  const combinedString = `${email}`
  const hash = hashString(combinedString)

  // Use the absolute value of the hash to ensure a positive index
  const index = Math.abs(hash) % TW_COLORS_USER_AVATAR_SAFE.length
  return TW_COLORS_USER_AVATAR_SAFE[index]
}

type AlertType =
  | 'destructive'
  | 'destructive-secondary'
  | 'success'
  | 'success-secondary'
  | 'warning'
  | 'warning-secondary'
  | 'info'
  | 'info-secondary'
  | 'neutral'
  | 'neutral-secondary'

export const getAlertColor = (
  alertType: AlertType,
  type: 'border' | 'bg' = 'bg'
) => {
  const mapping = {
    destructive: {
      bg: 'alert-danger-primary',
      border: 'alert-danger-primary',
    },
    'destructive-secondary': {
      bg: 'alert-danger-secondary',
      border: 'alert-danger-primary',
    },
    success: {
      bg: 'alert-success-primary',
      border: 'alert-success-primary',
    },
    'success-secondary': {
      bg: 'alert-success-secondary',
      border: 'alert-success-primary',
    },
    warning: {
      bg: 'alert-warning-primary',
      border: 'alert-warning-primary',
    },
    'warning-secondary': {
      bg: 'alert-warning-secondary',
      border: 'alert-warning-primary',
    },
    info: {
      bg: 'alert-info-primary',
      border: 'alert-info-primary',
    },
    'info-secondary': {
      bg: 'alert-info-secondary',
      border: 'alert-info-primary',
    },
    neutral: {
      bg: 'alert-neutral-primary',
      border: 'alert-neutral-primary',
    },
    'neutral-secondary': {
      bg: 'alert-neutral-secondary',
      border: 'alert-neutral-primary',
    },
  }

  return mapping[alertType][type]
}
